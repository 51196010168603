import { Box, HStack, Show } from '@chakra-ui/react';
import {
  useGetAdminOrders,
  useGetAllMerchants,
  useGetRiders,
  useOrderPreviewState,
  useSelectedDeliveryState,
  useSelectedOrderState,
  useSubscribeToRiderLocations,
} from '@tradeaze/frontend/hooks';
import {
  AssignScheduleContainer,
  OrderQueryPage,
  Layout,
  Map,
  MapPointSelectMenu,
  MarkerFilterMenu,
  OrderDashboardContext,
  OrderDeliveriesContainer,
  OrderPreviewModal,
  TwoColumnLayout,
  useAssignDeliveryContext,
  useDashboardMapMarkers,
  useMarkerHover,
  useOrderQueryStore,
  OrderDashboardControls,
  MobileMapWithToggle,
} from '@tradeaze/frontend/ui-web';
import { getIsOnDuty } from '@tradeaze/shared/utils';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export const OrderDashboardPage = () => {
  const navigate = useNavigate();

  const orderQueryStore = useOrderQueryStore();

  const {
    data: paginatedOrders,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetAdminOrders({
    orderStatuses: orderQueryStore.orderStatuses,
    sortBy: orderQueryStore.sortBy,
    endDate: orderQueryStore.endDate,
    startDate: orderQueryStore.startDate,
    merchantOrderReference: orderQueryStore.merchantOrderReference,
    deliveryToday: orderQueryStore.deliveryToday,
    riderIds: orderQueryStore.riderIds,
    deliveryVehicles: orderQueryStore.deliveryVehicles,
    workTypes: orderQueryStore.workTypes,
    merchantIds: orderQueryStore.merchantIds,
    isUnassigned: orderQueryStore.isUnassigned,
    isOverRunning: orderQueryStore.isOverRunning,
    isOnJobBoard: orderQueryStore.isOnJobBoard,
    limit: 50,
  });

  const orders = useMemo(
    () => paginatedOrders?.pages.flatMap((page) => page.orders) || [],
    [paginatedOrders],
  );

  const deliveries = useMemo(
    () =>
      orders.flatMap((order) =>
        order.deliveries.map((delivery) => ({ ...delivery, order })),
      ),
    [orders],
  );

  const { data: riders } = useGetRiders({
    includeDeleted: false,
    includeUnassignable: false,
    withLocation: false,
  });

  const clockedInTodayRiders = useMemo(
    () =>
      riders?.filter((rider) =>
        rider.lastClockedInAt ? getIsOnDuty(rider.lastClockedInAt) : false,
      ),
    [riders],
  );

  const riderLocations = useSubscribeToRiderLocations(
    clockedInTodayRiders?.map((rider) => rider.riderId) || [],
  );

  const { data: merchants } = useGetAllMerchants();

  const { selectedDelivery, onSelectDelivery } = useSelectedDeliveryState();

  const { selectedOrder, onSelectOrder } = useSelectedOrderState();

  const assignDeliveryContext = useAssignDeliveryContext();

  const orderPreviewState = useOrderPreviewState();

  const markerHover = useMarkerHover();

  const onPressGoToOrder = (orderId: string) => {
    navigate(`/orders/${orderId}`);
  };

  const { handleMarkerFilterChange, mapMarkers, mapPoints, markerFilters } =
    useDashboardMapMarkers({
      deliveries,
      merchants,
      riders: clockedInTodayRiders,
      riderLocations,
      selectedDelivery,
      isAdmin: true,
      onClickDelivery: orderPreviewState.handleOpenPreview,
      initialFilters: {
        MERCHANT: false,
        DROP_OFF: true,
        PICK_UP: true,
        RIDER: true,
        ADDRESS: true,
        DELIVERY: true,
      },
      ...markerHover,
    });

  const dashboardContextValue = useMemo(
    () => ({
      handleStartAssign: assignDeliveryContext.handleStartAssign,
      handlePreviewOrder: orderPreviewState.handleOpenPreview,
    }),
    [
      assignDeliveryContext.handleStartAssign,
      orderPreviewState.handleOpenPreview,
    ],
  );

  const OrderGrid = (
    <OrderDashboardContext.Provider value={dashboardContextValue}>
      <Box pt={[0, 0, 10]}>
        <OrderQueryPage
          groupByOrder={true}
          onPressGoTo={onPressGoToOrder}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFiltered={orderQueryStore.getActiveFilters() > 0}
          isAdmin={true}
          isError={isError}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
          orders={orders}
          deliveries={deliveries}
          columns={[1, 1, 1, 1, 2]}
          selectedDelivery={selectedDelivery}
          selectedOrder={selectedOrder}
          onSelectDelivery={onSelectDelivery}
          onSelectOrder={onSelectOrder}
        />
      </Box>
    </OrderDashboardContext.Provider>
  );

  return (
    <OrderDashboardContext.Provider value={dashboardContextValue}>
      {assignDeliveryContext.deliveryToAssign && (
        <AssignScheduleContainer
          deliveryToAssign={assignDeliveryContext.deliveryToAssign}
          handleCancelAssign={assignDeliveryContext.handleCancelAssign}
          isLoading={assignDeliveryContext.isLoading}
          allDeliveries={deliveries}
        />
      )}

      {selectedOrder && (
        <OrderDeliveriesContainer
          initialDate={new Date()}
          selectedOrder={selectedOrder}
          merchants={merchants}
          clockedInTodayRiders={clockedInTodayRiders}
          riderLocations={riderLocations}
          isAdmin={true}
          onPressGoTo={onPressGoToOrder}
          selectedDelivery={selectedDelivery}
          onSelectDelivery={onSelectDelivery}
          onSelectOrder={onSelectOrder}
          onClickDelivery={orderPreviewState.handleOpenPreview}
        />
      )}

      <OrderPreviewModal
        orderId={orderPreviewState.selectedOrder?.orderId}
        isAdmin={true}
        isTravisPerkins={false}
        isOpen={Boolean(orderPreviewState.selectedOrder)}
        onClose={orderPreviewState.handleClosePreview}
      />

      <Show above="md">
        <TwoColumnLayout
          left={OrderGrid}
          right={
            <>
              <Map
                markers={mapMarkers}
                cursor={mapPoints.enabled ? 'crosshair' : 'grab'}
                onClick={mapPoints.handleClick}
              />
              <HStack position={'fixed'} bottom={4} right={4}>
                <MapPointSelectMenu {...mapPoints} />
                <MarkerFilterMenu
                  markerFilters={markerFilters}
                  handleMarkerFilterChange={handleMarkerFilterChange}
                />
              </HStack>
            </>
          }
          leftContainerProps={{
            paddingX: 6,
          }}
        />
        <OrderDashboardControls
          orderQueryStore={orderQueryStore}
          isAdmin={true}
        />
      </Show>
      <Show below="md">
        <Layout>
          <Box pt={10}>{OrderGrid}</Box>
          <MobileMapWithToggle
            mapProps={{
              markers: mapMarkers,
            }}
            topPx={40}
          />
          <OrderDashboardControls
            orderQueryStore={orderQueryStore}
            isAdmin={true}
          />
        </Layout>
      </Show>
    </OrderDashboardContext.Provider>
  );
};
